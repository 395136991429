import React from 'react'
import { Link } from 'gatsby'
import Header from './Header.js'
import { StaticQuery, graphql } from 'gatsby'
import 'normalize.css'
import '../styles/main.scss'

const Query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default ({ children }) => (
  <StaticQuery
    query={Query}
    render={(data) => {
      // const { title, siteDescription } = data.site.siteMetadata
      return (
        <>
          <Header />

          <div class="main-container">
            <header class="header">
              <Link to="/" className="header__logo">
                <h1>Shovan Chatterjee</h1>
              </Link>
              <nav>
                <Link to="/" className="header__link">
                  Writings
                </Link>
                <Link to="/topics" className="header__link">
                  Topics
                </Link>
                <Link to="/readings" className="header__link">
                  Readings
                </Link>
              </nav>
            </header>
            <main>{children}</main>
          </div>
        </>
      )
    }}
  />
)
