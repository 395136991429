import React from 'react'

import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

const Query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteDescription
      }
    }
  }
`

export default ({ children }) => (
  <StaticQuery
    query={Query}
    render={data => {
      const { title, siteDescription } = data.site.siteMetadata
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <html lang="en" />
            <meta name="description" content={siteDescription} />
            <title>{title}</title>
            <body class="container" />
          </Helmet>
        </>
      )
    }}
  />
)
